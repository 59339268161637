import Cookie from 'js.cookie';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    if (!Cookie.get('alert') && $('#modal').length || $('#modal').hasClass('no-cookie')) {
      $('#modal').show();
      Cookie.remove('alert');
    }

    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $('.hamburger').click(function () {
          $('.hamburger').toggleClass('is-active');
          $('#sideNav').toggleClass('sideNav-open');
          $('.sideNavBody').toggleClass('sideNavBody-push');
          removeClass = false;
        });
    
        $('.sideNav').click(function() {
          removeClass = false;
        });
    
        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
            $('.hamburger').removeClass('is-active');
            $('#sideNav').removeClass('sideNav-open');
            $('.sideNavBody').removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);
    
            //dropdown on click
       $('.banner nav li.menu-item-has-children > a').click(function(e) {
        e.preventDefault();
        $(this).parent('.banner nav li.menu-item-has-children').toggleClass('open');
        $(this).parent('.banner nav li.menu-item-has-children').siblings('.open').removeClass('open');
      });
    
      //close dropdown when clicking anywhere else
      $(document).on('click touch', function(e) {
        if( !$(e.target).hasClass('.banner nav li.menu-item-has-children') && !$(e.target).parents('.banner nav li.menu-item-has-children').length ) {
          $('.banner nav li.menu-item-has-children.open').removeClass('open');
        }
      });
    
      $('.sideNav li.menu-item-has-children > a').click(function(e){
        e.preventDefault();
        $(this).next('.sideNav .sub-menu').slideToggle();
        $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
      });

    lightGallery(document.getElementById('project-gallery'), {
      licenseKey: 'F28B4CA0-283749F1-BBEB75B9-915E2B0B',
      selector: '.gallery-image',
      plugins: [lgZoom, lgThumbnail, fullScreen],
      speed: 500,
      preload: 1,
      fullScreen: true,
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: false,
      },
    });

    if ($('.public-notices-carousel').length) {
      $('.public-notices-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        appendArrows: '.front-page-public-notices-panel .public-notices-carousel',
        dots: false,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

    if ($('.mobile-public-notices-carousel').length) {
      $('.mobile-public-notices-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        appendArrows: '.front-page-public-notices-panel .mobile-public-notices-carousel-arrows',
        dots: false,
        responsive: [
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

//shortens long text and adds read more/less links.
function addReadMoreLink(className, maxLength) {
  $('.' + className).each(function() {
    var text = $(this).text();
    if(text.length > maxLength) {
      var shortText = text.substr(0, maxLength);
      var longText = text.substr(maxLength);
      var html = shortText + '<a href="#" class="read-more">... More</a><span class="more-text">' + longText + '</span><a href="#" class="read-less"> Less</a>';
      $(this).html(html);
      $(this).find('.more-text').hide();
      $(this).find('.read-less').hide();
    }
  });
  $('.read-more').click(function(e) {
    e.preventDefault();
    $(this).hide();
    $(this).siblings('.more-text').show();
    $(this).siblings('.read-less').show();
  });

  $('.read-less').click(function(e) {
    e.preventDefault();
    $(this).hide();
    $(this).siblings('.more-text').hide();
    $(this).siblings('.read-more').show();
  });
}

//function call back (text class name, text max length)
addReadMoreLink('feature-text', 200);
  },
};
